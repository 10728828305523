@import url("https://fonts.googleapis.com/css2?family=Nunito");
@import url("https://fonts.googleapis.com/css2?family=Roboto");

* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  font-family: Nunito, "sans-serif";
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.ant-checkbox-inner {
  border-radius: 2px !important;
}

@primary-color: #2B6FF2;@border-radius-base: 5px;@typography-title-font-weight: 700;@btn-font-weight: 700;@border-color-base: #AAAAAA;@input-hover-border-color: #AAAAAA;@calendar-item-active-bg: #FFFFFF;@checkbox-border-radius: 2px;